import React from 'react';
import { Button, Form, Input, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import './CartaForm.scss';
import { dummyRequest, normFile } from '../../../utils/upload';

const CartaForm = () => {
  return <div className="CartaForm">

    <h4>Capa</h4>
    <div className="line">
      <Form.Item
        name="carta_imagem1"
        label="Imagem 1"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Upload
          name="carta_imagem1"
          customRequest={dummyRequest}
          accept=".jpg,.jpeg,.png,.gif"
          listType="picture"
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Selecionar Imagem</Button>
        </Upload>
      </Form.Item>
    </div>

    <h4>Página 2</h4>
    <div className="line">
      <Form.Item
        name="carta_imagem2"
        label="Imagem 2"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Upload
          name="carta_imagem2"
          customRequest={dummyRequest}
          accept=".jpg,.jpeg,.png,.gif"
          listType="picture"
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Selecionar Imagem</Button>
        </Upload>
      </Form.Item>

      <Form.Item
        name="carta_qrcode2_x"
        label="QRCode posição horizontal"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="carta_qrcode2_y"
        label="QRCode posição vertical"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <h4>Página 3</h4>
    <div className="line">
      <Form.Item
        name="carta_imagem3"
        label="Imagem 3"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Upload
          name="carta_imagem3"
          customRequest={dummyRequest}
          accept=".jpg,.jpeg,.png,.gif"
          listType="picture"
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Selecionar Imagem</Button>
        </Upload>
      </Form.Item>

      <Form.Item
        name="carta_qrcode3_x"
        label="QRCode posição horizontal"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="carta_qrcode3_y"
        label="QRCode posição vertical"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <h4>Página 4</h4>
    <div className="line">
      <Form.Item
        name="carta_imagem4"
        label="Imagem 4"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Upload
          name="carta_imagem4"
          customRequest={dummyRequest}
          accept=".jpg,.jpeg,.png,.gif"
          listType="picture"
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Selecionar Imagem</Button>
        </Upload>
      </Form.Item>

      <Form.Item
        name="carta_qrcode4_x"
        label="QRCode posição horizontal"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="carta_qrcode4_y"
        label="QRCode posição vertical"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <h4>Página 5</h4>
    <div className="line">
      <Form.Item
        name="carta_imagem5"
        label="Imagem 5"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Upload
          name="carta_imagem5"
          customRequest={dummyRequest}
          accept=".jpg,.jpeg,.png,.gif"
          listType="picture"
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Selecionar Imagem</Button>
        </Upload>
      </Form.Item>

      <Form.Item
        name="carta_qrcode5_x"
        label="QRCode posição horizontal"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="carta_qrcode5_y"
        label="QRCode posição vertical"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <h4>Página 6</h4>
    <div className="line">
      <Form.Item
        name="carta_imagem6"
        label="Imagem 6"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Upload
          name="carta_imagem6"
          customRequest={dummyRequest}
          accept=".jpg,.jpeg,.png,.gif"
          listType="picture"
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Selecionar Imagem</Button>
        </Upload>
      </Form.Item>

      <Form.Item
        name="carta_qrcode6_x"
        label="QRCode posição horizontal"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="carta_qrcode6_y"
        label="QRCode posição vertical"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <h4>Página 7</h4>
    <div className="line">
      <Form.Item
        name="carta_imagem7"
        label="Imagem 7"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Upload
          name="carta_imagem7"
          customRequest={dummyRequest}
          accept=".jpg,.jpeg,.png,.gif"
          listType="picture"
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Selecionar Imagem</Button>
        </Upload>
      </Form.Item>

      <Form.Item
        name="carta_qrcode7_x"
        label="QRCode posição horizontal"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="carta_qrcode7_y"
        label="QRCode posição vertical"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <h4>Página 8</h4>
    <div className="line">
      <Form.Item
        name="carta_imagem8"
        label="Imagem 8"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Upload
          name="carta_imagem8"
          customRequest={dummyRequest}
          accept=".jpg,.jpeg,.png,.gif"
          listType="picture"
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Selecionar Imagem</Button>
        </Upload>
      </Form.Item>

      <Form.Item
        name="carta_qrcode8_x"
        label="QRCode posição horizontal"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="carta_qrcode8_y"
        label="QRCode posição vertical"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <h4>Página 9</h4>
    <div className="line">
      <Form.Item
        name="carta_imagem9"
        label="Imagem 9"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Upload
          name="carta_imagem9"
          customRequest={dummyRequest}
          accept=".jpg,.jpeg,.png,.gif"
          listType="picture"
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Selecionar Imagem</Button>
        </Upload>
      </Form.Item>

      <Form.Item
        name="carta_qrcode9_x"
        label="QRCode posição horizontal"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="carta_qrcode9_y"
        label="QRCode posição vertical"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <h4>Página 10</h4>
    <div className="line">
      <Form.Item
        name="carta_imagem10"
        label="Imagem 10"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Upload
          name="carta_imagem10"
          customRequest={dummyRequest}
          accept=".jpg,.jpeg,.png,.gif"
          listType="picture"
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Selecionar Imagem</Button>
        </Upload>
      </Form.Item>

      <Form.Item
        name="carta_qrcode10_x"
        label="QRCode posição horizontal"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="carta_qrcode10_y"
        label="QRCode posição vertical"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <h4>Página 11</h4>
    <div className="line">
      <Form.Item
        name="carta_imagem11"
        label="Imagem 11"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Upload
          name="carta_imagem11"
          customRequest={dummyRequest}
          accept=".jpg,.jpeg,.png,.gif"
          listType="picture"
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Selecionar Imagem</Button>
        </Upload>
      </Form.Item>

      <Form.Item
        name="carta_qrcode11_x"
        label="QRCode posição horizontal"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="carta_qrcode11_y"
        label="QRCode posição vertical"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <h4>Página 12</h4>
    <div className="line">
      <Form.Item
        name="carta_imagem12"
        label="Imagem 12"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Upload
          name="carta_imagem12"
          customRequest={dummyRequest}
          accept=".jpg,.jpeg,.png,.gif"
          listType="picture"
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Selecionar Imagem</Button>
        </Upload>
      </Form.Item>

      <Form.Item
        name="carta_qrcode12_x"
        label="QRCode posição horizontal"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="carta_qrcode12_y"
        label="QRCode posição vertical"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <h4>Página 13</h4>
    <div className="line">
      <Form.Item
        name="carta_imagem13"
        label="Imagem 13"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Upload
          name="carta_imagem13"
          customRequest={dummyRequest}
          accept=".jpg,.jpeg,.png,.gif"
          listType="picture"
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Selecionar Imagem</Button>
        </Upload>
      </Form.Item>

      <Form.Item
        name="carta_qrcode13_x"
        label="QRCode posição horizontal"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="carta_qrcode13_y"
        label="QRCode posição vertical"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <h4>Página 14</h4>
    <div className="line">
      <Form.Item
        name="carta_imagem14"
        label="Imagem 14"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Upload
          name="carta_imagem14"
          customRequest={dummyRequest}
          accept=".jpg,.jpeg,.png,.gif"
          listType="picture"
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Selecionar Imagem</Button>
        </Upload>
      </Form.Item>

      <Form.Item
        name="carta_qrcode14_x"
        label="QRCode posição horizontal"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="carta_qrcode14_y"
        label="QRCode posição vertical"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <h4>Página 15</h4>
    <div className="line">
      <Form.Item
        name="carta_imagem15"
        label="Imagem 15"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Upload
          name="carta_imagem15"
          customRequest={dummyRequest}
          accept=".jpg,.jpeg,.png,.gif"
          listType="picture"
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Selecionar Imagem</Button>
        </Upload>
      </Form.Item>

      <Form.Item
        name="carta_qrcode15_x"
        label="QRCode posição horizontal"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="carta_qrcode15_y"
        label="QRCode posição vertical"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <h4>Página 16</h4>
    <div className="line">
      <Form.Item
        name="carta_imagem16"
        label="Imagem 16"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Upload
          name="carta_imagem16"
          customRequest={dummyRequest}
          accept=".jpg,.jpeg,.png,.gif"
          listType="picture"
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Selecionar Imagem</Button>
        </Upload>
      </Form.Item>

      <Form.Item
        name="carta_qrcode16_x"
        label="QRCode posição horizontal"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="carta_qrcode16_y"
        label="QRCode posição vertical"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <h4>Página 17</h4>
    <div className="line">
      <Form.Item
        name="carta_imagem17"
        label="Imagem 17"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Upload
          name="carta_imagem17"
          customRequest={dummyRequest}
          accept=".jpg,.jpeg,.png,.gif"
          listType="picture"
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Selecionar Imagem</Button>
        </Upload>
      </Form.Item>

      <Form.Item
        name="carta_qrcode17_x"
        label="QRCode posição horizontal"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="carta_qrcode17_y"
        label="QRCode posição vertical"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <h4>Página 18</h4>
    <div className="line">
      <Form.Item
        name="carta_imagem18"
        label="Imagem 18"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Upload
          name="carta_imagem18"
          customRequest={dummyRequest}
          accept=".jpg,.jpeg,.png,.gif"
          listType="picture"
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Selecionar Imagem</Button>
        </Upload>
      </Form.Item>

      <Form.Item
        name="carta_qrcode18_x"
        label="QRCode posição horizontal"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="carta_qrcode18_y"
        label="QRCode posição vertical"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <h4>Página 19</h4>
    <div className="line">
      <Form.Item
        name="carta_imagem19"
        label="Imagem 19"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Upload
          name="carta_imagem19"
          customRequest={dummyRequest}
          accept=".jpg,.jpeg,.png,.gif"
          listType="picture"
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Selecionar Imagem</Button>
        </Upload>
      </Form.Item>

      <Form.Item
        name="carta_qrcode19_x"
        label="QRCode posição horizontal"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="carta_qrcode19_y"
        label="QRCode posição vertical"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>


  </div>
};
export default CartaForm;