import { getToday, getCurrentYear, getMonthSigla, getPreviousDay, getTextCurrentMonth, getToday2Year, dataExtenso, getTodayPlusDays, getMonthName } from '../../utils/date.js';

const initialValues = {
  date: getToday(),


  agenda_dia_data: getToday(),
  agenda_dia_data_extenso: dataExtenso(getToday()),
  agenda_dia: [
    { hora: '07:00', moeda: 'ue', descricao: 'IPC Zona do Euro (Anual) (Out)'},
    { hora: '07:00', moeda: 'ue', descricao: 'PIB (Anual) (Q3)'},
    { hora: '09:00', moeda: 'brasil', descricao: 'Taxa de Desemprego no Brasil'},
    { hora: '11:00', moeda: 'eua', descricao: 'Confiança do Consumidor CD (Out)'},
  ],



  agenda_semana_data_inicial: getToday(),
  agenda_semana_data_final: getTodayPlusDays(4),
  agenda_semana: [
    {
      dia_semana: 'Segunda-feira',
      data: '06/11',
      itens: [
        { hora: '06:00', moeda: 'ue', descricao: 'PIB - Alemanha (Trimestral)', periodo: ''},
        { hora: '08:00', moeda: 'brasil', descricao: 'IGP-M (Mensal)', periodo: ''},
      ],
    },
    {
      dia_semana: 'Terça-feira',
      data: '07/11',
      itens: [
        { hora: '07:00', moeda: 'ue', descricao: 'IPC Zona do Euro (Anual)', periodo: ''},
        { hora: '07:00', moeda: 'ue', descricao: 'IPIB (Anual)', periodo: ''},
      ],
    },
    {
      dia_semana: 'Quarta-feira',
      data: '08/11',
      itens: [
        { hora: '09:00', moeda: 'brasil', descricao: 'Produção Industrial (Anual)', periodo: ''},
        { hora: '09:00', moeda: 'brasil', descricao: 'Produção Industrial (Mensal)', periodo: ''},
      ],
    },
    {
      dia_semana: 'Quinta-feira',
      data: '09/11',
      itens: [
        { hora: '07:00', moeda: 'ue', descricao: 'IPC Zona do Euro (Anual)', periodo: ''},
        { hora: '07:00', moeda: 'ue', descricao: 'IPIB (Anual)', periodo: ''},
      ],
    },
    {
      dia_semana: 'Sexta-feira',
      data: '10/11',
      itens: [
        { hora: '07:00', moeda: 'ue', descricao: 'IPC Zona do Euro (Anual)', periodo: ''},
        { hora: '07:00', moeda: 'ue', descricao: 'IPIB (Anual)', periodo: ''},
      ],
    },
  ],


  agricultural_commodities_data: getTextCurrentMonth(),
  agricultural_commodities_hora: '18:15',
  agricultural_commodities_valor_real_soja: '140,11',
  agricultural_commodities_variacao_soja: '1,68',
  agricultural_commodities_status_soja: 'aumento',
  agricultural_commodities_valor_dolar_soja: '25,91',
  agricultural_commodities_valor_real_milho: '53,99',
  agricultural_commodities_variacao_milho: '0,84',
  agricultural_commodities_status_milho: 'aumento',
  agricultural_commodities_valor_dolar_milho: '11,07',
  agricultural_commodities_valor_real_cafe: '808,21',
  agricultural_commodities_variacao_cafe: '1,03',
  agricultural_commodities_status_cafe: 'aumento',
  agricultural_commodities_valor_dolar_cafe: '165,79',
  agricultural_commodities_valor_real_boi: '204,00',
  agricultural_commodities_variacao_boi: '2,10',
  agricultural_commodities_status_boi: 'diminuicao',
  agricultural_commodities_valor_dolar_boi: '41,85',
  agricultural_commodities_valor_real_trigo: '1.069,23',
  agricultural_commodities_variacao_trigo: '7,54',
  agricultural_commodities_status_trigo: 'diminuicao',
  agricultural_commodities_valor_dolar_trigo: '219,33',
  agricultural_commodities_valor_real_acucar: '387,42',
  agricultural_commodities_variacao_acucar: '0,72',
  agricultural_commodities_status_acucar: 'diminuicao',



  juros_futuros_data: getToday(),
  juros_futuros_hora: '14:00',
  juros_futuros_model: 'stories',
  juros_futuros: [
    { nome: 'DI1F25', vencimento: 'Vencimento JAN/25', status: 'diminuicao', variacao: '1,91', taxa: '10,65' },
    { nome: 'DI1F27', vencimento: 'Vencimento JAN/27', status: 'aumento', variacao: '5,31', taxa: '11,79' },
    { nome: 'DI1F28', vencimento: 'Vencimento JAN/28', status: 'aumento', variacao: '5,02', taxa: '12,02' },
    { nome: 'DI1F29', vencimento: 'Vencimento JAN/29', status: 'aumento', variacao: '4,61', taxa: '10,15' },
    { nome: 'DI1F31', vencimento: 'Vencimento JAN/31', status: 'aumento', variacao: '4,24', taxa: '12,29' },
    { nome: 'DI1F33', vencimento: 'Vencimento JAN/33', status: 'diminuicao', variacao: '3,79', taxa: '10,31' },
  ],



  mercados_globais_data: getToday(),
  mercados_globais_hora: '12:00',
  mercados_globais_nasdaq_pontos: '17.855',
  mercados_globais_nasdaq_variacao: '0.94',
  mercados_globais_nasdaq_status: 'aumento',
  mercados_globais_dow_jones_pontos: '38.778',
  mercados_globais_dow_jones_variacao: '0.49',
  mercados_globais_dow_jones_status: 'aumento',
  mercados_globais_sp_500_pontos: '5.475',
  mercados_globais_sp_500_variacao: '0.30',
  mercados_globais_sp_500_status: 'aumento',
  mercados_globais_ibovespa_pontos: '119.138',
  mercados_globais_ibovespa_variacao: '0.44',
  mercados_globais_ibovespa_status: 'diminuicao',
  mercados_globais_merval_pontos: '1.582.372',
  mercados_globais_merval_variacao: '1.42',
  mercados_globais_merval_status: 'diminuicao',
  mercados_globais_paris_pontos: '7.572',
  mercados_globais_paris_variacao: '0.91',
  mercados_globais_paris_status: 'aumento',
  mercados_globais_londres_pontos: '8.142',
  mercados_globais_londres_variacao: '1.30',
  mercados_globais_londres_status: 'diminuicao',
  mercados_globais_frankfurt_pontos: '18.074',
  mercados_globais_frankfurt_variacao: '0.40',
  mercados_globais_frankfurt_status: 'aumento',
  mercados_globais_hang_seng_pontos: '17.936',
  mercados_globais_hang_seng_variacao: '0.03',
  mercados_globais_hang_seng_status: 'diminuicao',
  mercados_globais_hang_seng_fechamento: getPreviousDay(),
  mercados_globais_nikkei_pontos: '38.075',
  mercados_globais_nikkei_variacao: '0.20',
  mercados_globais_nikkei_status: 'aumento',
  mercados_globais_nikkei_fechamento: getPreviousDay(),



  mercado_crypto_hora: '13:00',
  mercado_crypto_valor_bitcoin: '66,923.03',
  mercado_crypto_variacao_bitcoin: '2.80',
  mercado_crypto_status_bitcoin: 'aumento',
  mercado_crypto_valor_ethereum: '3,087.46',
  mercado_crypto_variacao_ethereum: '5.05',
  mercado_crypto_status_ethereum: 'aumento',
  mercado_crypto_valor_bnb: '579.91',
  mercado_crypto_variacao_bnb: '2.25',
  mercado_crypto_status_bnb: 'aumento',
  mercado_crypto_valor_solana: '168.76',
  mercado_crypto_variacao_solana: '6.48',
  mercado_crypto_status_solana: 'aumento',
  mercado_crypto_valor_xrp: '0.5237',
  mercado_crypto_variacao_xrp: '1.23',
  mercado_crypto_status_xrp: 'aumento',
  mercado_crypto_valor_dogecoin: '0.1545',
  mercado_crypto_variacao_dogecoin: '3.23',
  mercado_crypto_status_dogecoin: 'aumento',
  mercado_crypto_valor_cardano: '0.4821',
  mercado_crypto_variacao_cardano: '5.75',
  mercado_crypto_status_cardano: 'aumento',


  bitcoin_ethereum_modelo: 'ethereum',
  bitcoin_ethereum_data: getToday(),
  bitcoin_ethereum_horario_brasilia: '14:46',
  bitcoin_ethereum_atual_valor: '3,134.31',
  bitcoin_ethereum_atual_variacao: '4.81',
  bitcoin_ethereum_atual_status: 'diminuicao',
  bitcoin_ethereum_volume_valor: '20,77',
  bitcoin_ethereum_volume_variacao: '50.20',
  bitcoin_ethereum_volume_status: 'aumento',
  bitcoin_ethereum_capitalizacao_valor: '376.74',
  bitcoin_ethereum_capitalizacao_variacao: '4.87',
  bitcoin_ethereum_capitalizacao_status: 'diminuicao',
  bitcoin_ethereum_circulacao: '120.2',
  bitcoin_ethereum_emitido: '',
  bitcoin_ethereum_liquidez: '5.51',
  bitcoin_ethereum_maxima_valor: '4,891.70',
  bitcoin_ethereum_maxima_variacao: '35.88',
  bitcoin_ethereum_maxima_status: 'diminuicao',
  bitcoin_ethereum_maxima_data: 'NOV 16, 2021',


  feed_alta_nome1: 'EMBR3',
  feed_alta_variacao1: '4,35',
  feed_alta_nome2: 'PETR4',
  feed_alta_variacao2: '4,33',
  feed_alta_nome3: 'PETR3',
  feed_alta_variacao3: '3,79',
  feed_alta_nome4: 'RDOR3',
  feed_alta_variacao4: '2,94',
  feed_alta_nome5: 'RAIZ4',
  feed_alta_variacao5: '2,29',
  feed_alta_nome6: 'IRBR3',
  feed_alta_variacao6: '1,40',

  feed_baixa_nome1: 'MGLU3',
  feed_baixa_variacao1: '15,38',
  feed_baixa_nome2: 'CVCB3',
  feed_baixa_variacao2: '14,38',
  feed_baixa_nome3: 'MRVE3',
  feed_baixa_variacao3: '9,96',
  feed_baixa_nome4: 'CRFB3',
  feed_baixa_variacao4: '9,32',
  feed_baixa_nome5: 'MRFG3',
  feed_baixa_variacao5: '7,74',
  feed_baixa_nome6: 'ARZZ3',
  feed_baixa_variacao6: '7,71',

  feed_currency_variacao_ibovespa: '2,25',
  feed_currency_total_ibovespa: '113.155,28',
  feed_currency_status_ibovespa: 'diminuicao',
  feed_currency_variacao_bitcoin: '9,29',
  feed_currency_total_bitcoin: '149.217,01',
  feed_currency_status_bitcoin: 'aumento',
  feed_currency_variacao_dolar: '0,87',
  feed_currency_total_dolar: '5,04',
  feed_currency_status_dolar: 'diminuicao',


  focus_modelo: 'stories',
  focus_year_last: getCurrentYear(),
  focus_year_current: getCurrentYear() + 1,
  focus_ipca_last: '4,86',
  focus_ipca_current: '3,88',
  focus_ipca_last_status: 'estabilidade',
  focus_ipca_current_status: 'estabilidade',
  focus_cambio_last: '5,00',
  focus_cambio_current: '5,02',
  focus_cambio_last_status: 'estabilidade',
  focus_cambio_current_status: 'estabilidade',
  focus_pib_last: '2,92',
  focus_pib_current: '1,50',
  focus_pib_last_status: 'estabilidade',
  focus_pib_current_status: 'estabilidade',
  focus_selic_last: '11,75',
  focus_selic_current: '9,00',
  focus_selic_last_status: 'estabilidade',
  focus_selic_current_status: 'estabilidade',





  fechamento_mercado_fundo_assessores: 'azul',
  fechamento_mercado_hora: '17:37',
  fechamento_mercado_alta: [
    { nome: 'PRIO3', nome_completo: 'Prio', variacao: '5,02', total: '46,00' },
    { nome: 'RECV3', nome_completo: 'PetroRecôncavo', variacao: '3,76', total: '21,24' },
    { nome: 'CMIN3', nome_completo: 'CSN Mineração', variacao: '1,41', total: '5,02' },
    { nome: 'VAMO3', nome_completo: 'Vamos', variacao: '1,24', total: '9,01' },
    { nome: 'VIVT3', nome_completo: 'Telefônica Brasil', variacao: '1,07', total: '49,09' },
  ],
  fechamento_mercado_baixa: [
    { nome: 'CRFB3', nome_completo: 'Atacadão', variacao: '7,25', total: '9,59' },
    { nome: 'PETZ3', nome_completo: 'Petz', variacao: '5,60', total: '3,54' },
    { nome: 'ASAI3', nome_completo: 'Assaí', variacao: '4,58', total: '10,00' },
    { nome: 'MULT3', nome_completo: 'Multiplan', variacao: '4,35', total: '24,16' },
    { nome: 'MGLU3', nome_completo: 'Magazine Luiza', variacao: '4,19', total: '11,67' },
  ],
  fechamento_mercado_ibovespa_variacao: '2,15',
  fechamento_mercado_ibovespa_total: '116.145',
  fechamento_mercado_ibovespa_status: 'diminuicao',
  fechamento_mercado_bitcoin_real_variacao: '0,88',
  fechamento_mercado_bitcoin_real_total: '130.955,00',
  fechamento_mercado_bitcoin_real_status: 'diminuicao',
  fechamento_mercado_bitcoin_dolar_variacao: '2,24',
  fechamento_mercado_bitcoin_dolar_total: '26.564',
  fechamento_mercado_bitcoin_dolar_status: 'diminuicao',
  fechamento_mercado_dolar_variacao: '1,13',
  fechamento_mercado_dolar_total: '4,93',
  fechamento_mercado_dolar_status: 'aumento',




  nasdaq_date: getToday2Year(),
  nasdaq_alta1_nome: 'ARQ',
  nasdaq_alta1_variacao: '35.54',
  nasdaq_alta2_nome: 'VRM',
  nasdaq_alta2_variacao: '35.27',
  nasdaq_alta3_nome: 'ANNX',
  nasdaq_alta3_variacao: '28.54',
  nasdaq_baixa1_nome: 'CMTL',
  nasdaq_baixa1_variacao: '22.05',
  nasdaq_baixa2_nome: 'LRE',
  nasdaq_baixa2_variacao: '20.45',
  nasdaq_baixa3_nome: 'NMRA',
  nasdaq_baixa3_variacao: '16.20',
  nasdaq_composite_total: '16,177.77',
  nasdaq_composite_variacao_valor: '87.87',
  nasdaq_composite_variacao_porcentagem: '0.54',
  nasdaq_composite_status: 'diminuicao',



  indices_americanos_model: 'stories',
  indices_americanos_date: getToday(),
  indices_americanos_hora: '18:00',
  indices_americanos_metade: [
    { nome: 'NASDAQ', valor: '16,858.67', variacao: '0.56', status: 'aumento' },
    { nome: 'S&P 500', valor: '5,283.40', variacao: '0.11', status: 'aumento'},
    { nome: 'US 10-YR', valor: '4.396', variacao: '0.11', status: 'diminuicao' },
    { nome: 'DOW JONES', valor: '39,261.94', variacao: '1.29', status: 'aumento' },
    { nome: 'RUSS 2K', valor: '2,059.68', variacao: '0.50', status: 'aumento' },
    { nome: 'VIX', valor: '13.11', variacao: '1.47', status: 'aumento' },
  ],
  indices_americanos_auxilio_desemprego_atual: '219K',
  indices_americanos_auxilio_desemprego_anterior: '216K',
  indices_americanos_auxilio_desemprego_divulgacao: 'Divulgações recentes 30.05.2024',
  indices_americanos_pce_atual: '2,7%',
  indices_americanos_pce_anterior: '2,7%',
  indices_americanos_pce_divulgacao: 'Divulgações recentes 30.05.2024',




  minute_cor: 'azul',


  formula_vencedor_texto: 'Vencedora do sorteio',
  formula_sorteio_data: 'SECOM (DIA 21)',
  formula_vencedor_nome: '',


  noticia_acontecendo_brasil_titulo: "Lula diz que meta de zerar déficit fiscal em 2024 'dificilmente' será alcançada, mas afirmar que não cortará investimentos",
  noticia_acontecendo_brasil_tamanho_fonte_titulo: 46,
  noticia_acontecendo_brasil_texto1: 'Bolsa cai e renova mínima do dia após fala de Lula sobre déficit zero',
  noticia_acontecendo_brasil_texto2: 'Produção da Petrobras reforça visão de bons resultados e dividendos no 3° tri',
  noticia_acontecendo_brasil_texto3: 'Magalu (MGLU3) pode sair do índice MSCI e sofrer com pressão vendedora para ações, apontam analistas',
  noticia_acontecendo_brasil_cor_linha: 'verde',
  noticia_acontecendo_brasil_fonte_noticia: 'Fonte: Infomoney/CNN',

  noticia_acontecendo_mundo_titulo: "Putin acompanha simulação de ataque nuclear 'retaliatório'.",
  noticia_acontecendo_mundo_tamanho_fonte_titulo: 62,
  noticia_acontecendo_mundo_texto1: 'Trajetória política de Massa, vitorioso do 1° turno na Argentina, deu-lhe apelido de "panqueca"',
  noticia_acontecendo_mundo_texto2: 'Lagarde: juros estão em níveis que, se mantidos, ajudarão a levar inflação à meta',
  noticia_acontecendo_mundo_texto3: 'Milei sobre 2° turno das eleições na Argentina: "Serei uma tabula rasa"',
  noticia_acontecendo_mundo_cor_linha: 'azul',
  noticia_acontecendo_mundo_fonte_noticia: 'Fonte: Infomoney/CNN',


  noticia_urgente_titulo: 'Em reunião, Lula\npressiona deputados\npor projetos que\naumentem receita.',
  noticia_urgente_tamanho_fonte_titulo: 62,
  noticia_urgente_texto: 'O presidente Lula (PT) pressionou líderes da Câmara dos Deputados pela aprovação de projetos que aumentem a arrecadação do governo ainda neste ano. O encontro ocorre em meio a um debate sobre a meta fiscal de déficit zero para 2024',
  noticia_urgente_cor_linha: 'vermelho',
  noticia_urgente_fonte_noticia: 'Fonte: Infomoney/CNN',


  super_quarta_data: getToday2Year(),
  super_quarta_texto: 'As decisões do FED e COPOM\nmovimentaram o mercado hoje.',
  super_quarta_fed_valor1: '5,25',
  super_quarta_fed_valor2: '5,50',
  super_quarta_fed_texto: 'Referencial permaneceu inalterado pela segunda reunião consecutiva.\nJuros do país seguem no maior patamar desde 2001.',
  super_quarta_fed_fonte_texto: 'fonte: G1',
  super_quarta_copom_valor1: '12,25',
  super_quarta_copom_valor2: '',
  super_quarta_copom_texto: 'Terceiro corte seguido. Com a redução, a Selic atingiu seu menor nível\n desde maio de 2022.',
  super_quarta_copom_fonte_texto: 'fonte: G1',



  taxa_juros_eua_data: getToday2Year(),
  taxa_juros_eua_texto_principal: 'Decisão do Fed sobre a taxa de juros básica\namericana movimenta o mercado.',
  taxa_juros_eua_valor1: '5,25',
  taxa_juros_eua_valor2: '5,50',
  taxa_juros_eua_texto: 'Referencial permaneceu inalterado pela segunda reunião consecutiva.\nJuros do país seguem no maior patamar desde 2001.',
  taxa_juros_eua_fonte_texto: 'fonte: G1',



  taxa_selic_data: getToday2Year(),
  taxa_selic_texto:"Decisão do Copom sobre a taxa de juros\nmovimenta o mercado",
  taxa_selic_copom_valor1:"12,25",
  taxa_selic_copom_valor2:"",
  taxa_selic_copom_texto:"Terceiro corte seguido. Com a redução, a Selic atingiu\nseu menor nível desde maio de 2022.",
  taxa_selic_copom_fonte_texto:"fonte: G1",



  noticias_crypto_titulo: 'Títulos do Tesouro dos EUA tokenizados podem atingir US$ 3 bilhões até o final do ano.',
  noticias_crypto_items: [
    { noticia: 'Mt. Gox transfere US$ 9 bilhões em BTC e mercado se prepara para o impacto.' },
    { noticia: 'Criptomoedas sobem até 60% com o rali do Bitcoin e risco de queda de duas importantes altcoins.' },
    { noticia: 'Ether pode superar o Bitcoin após o lançamento do ETF à vista, aponta Kaiko.' },
  ],
  noticias_crypto_fonte: 'Binance News',



  twitter_hora: '14:53',
  twitter_dia: '15/10/2023',
  twitter_texto: 'Tem que investir!\n\nSe você investir apenas 400 por mês durante 20 anos vc vai ter um total de:\n\n370 mil\n\nQue não vai mudar em nada sua vida nem te aposentar então...\n\nCORRE e SE VIRA pra investir mais!\n\nBoa semana!',


  imagem_personalizada_profile_x: 75,
  imagem_personalizada_profile_y: 175,
  imagem_personalizada_profile_cor: { r: 255, g: 255, b: 255, a: 1 },
  imagem_personalizada_profile_fundo: 'claro',
  imagem_personalizada_qr_x: 600,
  imagem_personalizada_qr_y: 493,
  imagem_personalizada_qr_borda: 4,
  imagem_personalizada_qr_tamanho: 84,


  fund_ranking_classe: 'Multimercado',
  fund_ranking_descricao: 'Confira abaixo os fundos com maior retorno dos últimos 6 meses:',
  fund_ranking_nome1: 'Vitreo Uranio Fim',
  fund_ranking_porcentagem1: '52,32',
  fund_ranking_nome2: 'Hashdex Bitcoin',
  fund_ranking_porcentagem2: '48,99',
  fund_ranking_nome3: 'Empiricus Cripto',
  fund_ranking_porcentagem3: '48,36',
  fund_ranking_nome4: 'BLP Crypto',
  fund_ranking_porcentagem4: '48,31',
  fund_ranking_nome5: 'BTG Pactual',
  fund_ranking_porcentagem5: '45,73',






  fundos_imobiliarios_data: getToday(),
  fundos_imobiliarios_horario_brasilia: '15:00',
  fundos_imobiliarios_ifix_valor: '3.398,10',
  fundos_imobiliarios_ifix_variacao: '0,14',
  fundos_imobiliarios_ifix_status: 'aumento',
  fundos_imobiliarios_altas: [
    { sigla: 'RBRF11', variacao: '2,05', valor: '7,98' },
    { sigla: 'TVRI11', variacao: '1,80', valor: '101,90' },
    { sigla: 'TRBL11', variacao: '1,67', valor: '102,53' },
    { sigla: 'BPFF11', variacao: '1,58', valor: '71,39' },
  ],
  fundos_imobiliarios_baixas: [
    { sigla: 'VIUR11', variacao: '1,34', valor: '6,61' },
    { sigla: 'BROF11', variacao: '0,56', valor: '56,35' },
    { sigla: 'XPIN11', variacao: '0,43', valor: '78,46' },
    { sigla: 'RVBI11', variacao: '1,33', valor: '77,74' },
  ],





  
  iron_ore_date: getToday(),
  iron_ore_horario_brasilia: '09:00',
  iron_ore_fechamento_mes1: getMonthSigla(0),
  iron_ore_fechamento_valor1: '44.50',
  iron_ore_fechamento_variacao1: '3.1',
  iron_ore_fechamento_variacao_status1: 'aumento',
  iron_ore_fechamento_mes2: getMonthSigla(1),
  iron_ore_fechamento_valor2: '56.53',
  iron_ore_fechamento_variacao2: '1.5',
  iron_ore_fechamento_variacao_status2: 'diminuicao',
  iron_ore_fechamento_low: '85.8',
  iron_ore_fechamento_high: '87.3',
  iron_ore_fechamento_termometro: 'greed',
  iron_ore_live_mes1: getMonthSigla(0),
  iron_ore_live_valor1: '56.53',
  iron_ore_live_variacao1: '2.1',
  iron_ore_live_variacao_status1: 'diminuicao',
  iron_ore_live_mes2: getMonthSigla(1),
  iron_ore_live_valor2: '56.53',
  iron_ore_live_variacao2: '2.1',
  iron_ore_live_variacao_status2: 'diminuicao',
  iron_ore_live_low: '85.8',
  iron_ore_live_high: '87.3',
  iron_ore_live_termometro: 'greed',


  petroleo_date: getToday2Year(),
  petroleo_horario_brasilia: '09:00',
  petroleo_brent_valor: '88.07',
  petroleo_brent_variacao: '0.43',
  petroleo_brent_variacao_status: 'diminuicao',
  petroleo_brent_low: '87.6',
  petroleo_brent_high: '88.9',
  petroleo_brent_termometro: 'greed',
  petroleo_wti_valor: '88.07',
  petroleo_wti_variacao: '0.43',
  petroleo_wti_variacao_status: 'diminuicao',
  petroleo_wti_low: '82.4',
  petroleo_wti_high: '83.7',
  petroleo_wti_termometro: 'greed',


  metal_commodities_date: getToday(),
  metal_commodities_horario_brasilia: '11:10',
  metal_commodities_modelo: 'stories',
  metal_commodities_ouro_valor: '2.334,00',
  metal_commodities_ouro_variacao: '0,64',
  metal_commodities_ouro_variacao_status: 'diminuicao',
  metal_commodities_ouro_termometro: 'neutral',
  metal_commodities_ouro_mes: 'DEZ 24',
  metal_commodities_prata_valor: '29,39',
  metal_commodities_prata_variacao: '0,25',
  metal_commodities_prata_variacao_status: 'diminuicao',
  metal_commodities_prata_termometro: 'greed',
  metal_commodities_prata_mes: 'DEZ 24',
  metal_commodities_cobre_valor: '4,41',
  metal_commodities_cobre_variacao: '1,75',
  metal_commodities_cobre_variacao_status: 'diminuicao',
  metal_commodities_cobre_termometro: 'fear',
  metal_commodities_cobre_mes: 'DEZ 24',




  moedas_mundo_date: getToday(),
  moedas_mundo_horario_brasilia: '10:20',
  moedas_mundo_modelo: 'stories',
  moedas_mundo_dolar_valor: '5,99',
  moedas_mundo_dolar_variacao: '0,75',
  moedas_mundo_dolar_variacao_status: 'diminuicao',
  moedas_mundo_euro_valor: '6,32',
  moedas_mundo_euro_variacao: '0,44',
  moedas_mundo_euro_variacao_status: 'diminuicao',
  moedas_mundo_yuan_valor: '0,82',
  moedas_mundo_yuan_variacao: '0,78',
  moedas_mundo_yuan_variacao_status: 'diminuicao',
  moedas_mundo_libra_valor: '7,63',
  moedas_mundo_libra_variacao: '0,46',
  moedas_mundo_libra_variacao_status: 'diminuicao',
  moedas_mundo_franco_valor: '6,79',
  moedas_mundo_franco_variacao: '0,45',
  moedas_mundo_franco_variacao_status: 'diminuicao',
  moedas_mundo_canadense_valor: '4,27',
  moedas_mundo_canadense_variacao: '0,50',
  moedas_mundo_canadense_variacao_status: 'diminuicao',



  temporada_balancos_data: getToday2Year(),
  temporada_balancos: [
    { ticker: 'CAML3', horario: 'apos_fechamento'},
    { ticker: 'SANB11', horario: 'antes_abertura'},
    { ticker: 'BRBI11', horario: 'horario_confirmar'},
    { ticker: 'ITUB4', horario: 'apos_fechamento'},
    { ticker: 'BBSE3', horario: 'horario_confirmar'},
    { ticker: 'TIMS3', horario: 'apos_fechamento'},
    { ticker: 'LOGG3', horario: 'apos_fechamento'},
    { ticker: 'ALPA3', horario: 'horario_confirmar'},
    { ticker: 'KLBN11', horario: 'antes_abertura'},
    { ticker: 'AURE3', horario: 'apos_fechamento'},
  ],


  tesouro_direto_model: 'stories',
  tesouro_direto_data: getToday(),
  tesouro_direto_horario_brasilia: '13:00',
  tesouro_direto: [
    { value: 'IPCA+ 6,12%', name: 'TESOURO IPCA+', year: '2029', date: '15/05/2029', color: 'red', juros_semestrais: false },
    { value: 'IPCA+ 6,10%', name: 'TESOURO IPCA+', year: '2035', date: '15/05/2035', color: 'red', juros_semestrais: false },
    { value: 'IPCA+ 6,12%', name: 'TESOURO IPCA+', year: '2045', date: '15/05/2045', color: 'red', juros_semestrais: false },
    { value: '11,13%', name: 'TESOURO PREFIXADO', year: '2027', date: '01/01/2027', color: 'blue', juros_semestrais: false },
    { value: '11,82%', name: 'TESOURO PREFIXADO', year: '2031', date: '01/01/2031', color: 'blue', juros_semestrais: false },
    { value: '11,71%', name: 'TESOURO PREFIXADO', year: '2035', date: '01/01/2035', color: 'blue', juros_semestrais: true },
  ],



  fluxo_investimento_b3_data: getToday(),
  fluxo_investimento_b3_horario_brasilia: '14:46',
  fluxo_investimento_b3: [
    { name: 'ESTRANGEIRO', value: '40.12 bi', status: 'diminuicao', year: getCurrentYear(), value1: '-11.36 bi', month1: getMonthSigla(-3), value2: '-1.63 bi', month2: getMonthSigla(-2), value3: '-4.23 bi', month3: getMonthSigla(-1) },
    { name: 'INSTITUCIONAL', value: '1.60 bi', status: 'aumento', year: getCurrentYear(), value1: '+4.69 bi', month1: getMonthSigla(-3), value2: '-3.11 bi', month2: getMonthSigla(-2), value3: '-1.51 bi', month3: getMonthSigla(-1) },
    { name: 'PESSOA FÍSICA', value: '22.76 bi', status: 'aumento', year: getCurrentYear(), value1: '+4.12 bi', month1: getMonthSigla(-3), value2: '+2.42 bi', month2: getMonthSigla(-2), value3: '+3.57 bi', month3: getMonthSigla(-1) },
    { name: 'INST. FINANCEIRA', value: '6.54 bi', status: 'aumento', year: getCurrentYear(), value1: '+1.85 bi', month1: getMonthSigla(-3), value2: '-670.75 mi', month2: getMonthSigla(-2), value3: '-136.90 mi', month3: getMonthSigla(-1) },
  ],

  abertura_mercado_montebravo: [
    { nome: 'IBOVESPA FUTUROS', tipo: 'Brasil', variacao: '0,16', total: '128.816', status: 'aumento' },
    { nome: 'DÓLAR', tipo: 'Moeda', variacao: '0,01', total: 'R$ 5,64', status: 'diminuicao' },
    { nome: 'EURO', tipo: 'Moeda', variacao: '0,04', total: 'R$ 6,12', status: 'aumento' },
    { nome: 'US 500', tipo: 'S&P 500 Futuros', variacao: '0,86', total: '5.488,00', status: 'aumento' },
    { nome: 'US 30', tipo: 'Dow Jones Futuros', variacao: '0,66', total: '40.456,00', status: 'aumento' },
    { nome: 'US TECH 100', tipo: 'Nasdaq Futuros', variacao: '1,13', total: '19.206,00', status: 'aumento' },
    { nome: 'STOXX 50', tipo: 'Zona do Euro', variacao: '0,97', total: '4.858,08', status: 'aumento' },
    { nome: 'PETRÓLEO BRENT', tipo: '', variacao: '0,57', total: 'USD 80,90', status: 'diminuicao' },
    { nome: 'MINÉRIO DE FERRO', tipo: 'Dalian', variacao: '0,31', total: 'USD 101,90', status: 'diminuicao' },
  ],

  fechamento_mercado_montebravo: [
    { nome: 'IBOVESPA', tipo: 'Brasil', variacao: '0,13', total: '126.422,73', status: 'diminuicao' },
    { nome: 'DÓLAR', tipo: 'Moeda', variacao: '1,27', total: 'R$ 5,65', status: 'aumento' },
    { nome: 'EURO', tipo: 'Moeda', variacao: '1,17', total: 'R$ 6,13', status: 'aumento' },
    { nome: 'IFIX', tipo: 'Índice de Fundos Imobiliários', variacao: '0,18', total: '3.380,05', status: 'diminuicao' },
    { nome: 'S&P 500', tipo: 'Índice EUA', variacao: '2,31', total: '5.527,13', status: 'diminuicao' },
    { nome: 'DOW JONES', tipo: 'Índice EUA', variacao: '1,25', total: '39.853,87', status: 'diminuicao' },
    { nome: 'NASDAQ', tipo: 'Índice EUA', variacao: '3,62', total: '17.345,84', status: 'diminuicao' },
    { nome: 'STOXX 50', tipo: 'Índice Zona do Euro', variacao: '1,10', total: '4.862,60', status: 'diminuicao' },
    { nome: 'PETRÓLEO BRENT', tipo: '', variacao: '0,57', total: 'USD 81,47', status: 'aumento' },
  ],



  jornal_qrcode2_x: 2192,
  jornal_qrcode2_y: 1785,
  jornal_qrcode3_x: 1324,
  jornal_qrcode3_y: 1798,
  jornal_qrcode4_x: 189,
  jornal_qrcode4_y: 3032,
  jornal_qrcode5_x: 192,
  jornal_qrcode5_y: 2210,
  jornal_qrcode6_x: 2216,
  jornal_qrcode6_y: 652,
  jornal_qrcode7_x: 798,
  jornal_qrcode7_y: 925,
  jornal_qrcode8_x: 1868,
  jornal_qrcode8_y: 2845,




  carta_qrcode2_x: 1314,
  carta_qrcode2_y: 1114,
  carta_qrcode3_x: 1314,
  carta_qrcode3_y: 1000,
  carta_qrcode4_x: 2150,
  carta_qrcode4_y: 1680,
  carta_qrcode5_x: 1130,
  carta_qrcode5_y: 1750,
  carta_qrcode6_x: 2100,
  carta_qrcode6_y: 1200,
  carta_qrcode7_x: 2100,
  carta_qrcode7_y: 740,
  carta_qrcode8_x: 1340,
  carta_qrcode8_y: 1060,
  carta_qrcode9_x: 2120,
  carta_qrcode9_y: 2325,
  carta_qrcode10_x: 2120,
  carta_qrcode10_y: 1310,
  carta_qrcode11_x: 2120,
  carta_qrcode11_y: 1735,
  carta_qrcode12_x: 1325,
  carta_qrcode12_y: 1080,
  carta_qrcode13_x: 2120,
  carta_qrcode13_y: 1150,
  carta_qrcode14_x: 1325,
  carta_qrcode14_y: 1080,
  carta_qrcode15_x: 1325,
  carta_qrcode15_y: 1080,
  carta_qrcode16_x: 2120,
  carta_qrcode16_y: 1690,
  carta_qrcode17_x: 2120,
  carta_qrcode17_y: 1720,
  carta_qrcode18_x: 2120,
  carta_qrcode18_y: 1230,
  carta_qrcode19_x: 2120,
  carta_qrcode19_y: 1900,






  ipca_data: getToday(),
  ipca_hora: '09:00',
  ipca_model: 'stories',

  ipca_principal_mes: 'AGOSTO',
  ipca_principal_nome: 'IPCA',
  ipca_principal_variacao: '0,02',
  ipca_principal_status: 'diminuicao',

  ipca_secundario_mes: 'AGOSTO',
  ipca_secundario_nome: 'IPCA-15',
  ipca_secundario_variacao: '0,19',
  ipca_secundario_status: 'aumento',

  ipca_month1_value: 'JUL',
  ipca_month1_status: 'aumento',
  ipca_month1_variacao: '0,38',
  ipca_month2_value: 'JUN',
  ipca_month2_status: 'aumento',
  ipca_month2_variacao: '0,21',
  ipca_month3_value: 'MAI',
  ipca_month3_status: 'aumento',
  ipca_month3_variacao: '0,46',
  ipca_year1_value: '2023',
  ipca_year1_status: 'aumento',
  ipca_year1_variacao: '4,62',
  ipca_year2_value: '2022',
  ipca_year2_status: 'aumento',
  ipca_year2_variacao: '5,72',
  ipca_year3_value: '2021',
  ipca_year3_status: 'aumento',
  ipca_year3_variacao: '10,06',

  ipca_setor1_nome: 'HABITAÇÃO',
  ipca_setor1_status: 'aumento',
  ipca_setor1_variacao: '0,51',
  ipca_setor2_nome: 'ALIMENTOS',
  ipca_setor2_status: 'aumento',
  ipca_setor2_variacao: '0,44',
  ipca_setor3_nome: 'RESIDÊNCIA',
  ipca_setor3_status: 'aumento',
  ipca_setor3_variacao: '0,74',
  ipca_setor4_nome: 'EDUCAÇÃO',
  ipca_setor4_status: 'aumento',
  ipca_setor4_variacao: '0,73',










  balanca_comercial_hora: '15:00',
  balanca_comercial_exportacao1_mes: getMonthName(0),
  balanca_comercial_exportacao1_ano: '2024',
  balanca_comercial_exportacao1_variacao: '3,4',
  balanca_comercial_exportacao1_status: 'diminuicao',
  balanca_comercial_exportacao1_valor: '19,03',
  balanca_comercial_exportacao2_mes: `${getMonthSigla(0)} - ${getMonthSigla(3)}`,
  balanca_comercial_exportacao2_ano: '2024',
  balanca_comercial_exportacao2_variacao: '0,6',
  balanca_comercial_exportacao2_status: 'diminuicao',
  balanca_comercial_exportacao2_valor: '274,49',
  balanca_comercial_importacao1_mes: getMonthName(0),
  balanca_comercial_importacao1_ano: '2024',
  balanca_comercial_importacao1_variacao: '15,5',
  balanca_comercial_importacao1_status: 'aumento',
  balanca_comercial_importacao1_valor: '19,03',
  balanca_comercial_importacao2_mes: `${getMonthSigla(0)} - ${getMonthSigla(3)}`,
  balanca_comercial_importacao2_ano: '2024',
  balanca_comercial_importacao2_variacao: '7,5',
  balanca_comercial_importacao2_status: 'aumento',
  balanca_comercial_importacao2_valor: '212,13',
  balanca_comercial_balanca1_mes: getMonthName(0),
  balanca_comercial_balanca1_ano: '2024',
  balanca_comercial_balanca1_variacao: '47,0',
  balanca_comercial_balanca1_status: 'diminuicao',
  balanca_comercial_balanca1_valor: '3,24',
  balanca_comercial_balanca2_mes: `${getMonthSigla(0)} - ${getMonthSigla(3)}`,
  balanca_comercial_balanca2_ano: '2024',
  balanca_comercial_balanca2_variacao: '20,9',
  balanca_comercial_balanca2_status: 'diminuicao',
  balanca_comercial_balanca2_valor: '62,36',
};

export { initialValues };